import { gsap } from 'gsap';
import { TransitionContext } from '@/context/TransitionContext';
import { useContext, useEffect, useRef, useState } from 'react';
import useIsomorphicLayoutEffect from '@/utils/useIsomorphicEffect';

interface TransitionLayoutProps {
  readonly children: React.ReactNode;
}

export default function TransitionLayout({ children }: TransitionLayoutProps) {
  const { timeline, background } = useContext(TransitionContext);
  const el = useRef<HTMLDivElement | null>(null);
  const [displayChildren, setDisplayChildren] = useState<React.ReactNode>(children);

  useIsomorphicLayoutEffect(() => {
    if (children !== displayChildren) {
      if (timeline.duration() === 0) {
        // No outro animations, so immediately transition
        setDisplayChildren(children);
      } else {
        // Play the outro animation
        timeline.play().then(() => {
          // Outro complete, reset the timeline
          timeline.seek(0).pause().clear();
          setDisplayChildren(children);
        });
      }
    }
  }, [children]);

  useEffect(() => {
    gsap.to(el.current, {
      background,
      duration: 1,
    });
  }, [background]);

  return <div ref={el}>{displayChildren}</div>;
}
