import React, { useState, createContext, useMemo } from 'react';
import gsap from 'gsap';
import { WithChildren } from '@/types/consts';

interface ITransitionContext {
  background?: string;
  setBackground?: any;
  timeline: gsap.core.Timeline;
  setTimeline?: (timeline: gsap.core.Timeline) => void;
}

const TransitionContext = createContext<ITransitionContext>({} as ITransitionContext);

const TransitionProvider = ({ children }: WithChildren) => {
  const [timeline, setTimeline] = useState(() => gsap.timeline({ paused: true }));

  const [background, setBackground] = useState('white');

  const contextValue = useMemo(
    () => ({
      timeline,
      setTimeline,
      background,
      setBackground,
    }),
    [timeline, setTimeline, background, setBackground],
  );
  return (
    <TransitionContext.Provider value={contextValue}>
      {children}
    </TransitionContext.Provider>
  );
};

export { TransitionContext, TransitionProvider };
